.Reasons {
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
    margin: 5% 0px -10px 0px;
  }
  
  .left-r {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
  }
  
  .left-r>img {
    object-fit: cover;
  }
  
  .left-r>:nth-child(1) {
    width: 20rem;
    grid-row: 1;
    grid-column: 2;
    height: 20rem
  }
  
  .left-r>:nth-child(2) {
    width: 25rem;
    height: 12rem;
    grid-column: 2/4;
  }
  
  .left-r>:nth-child(3) {
    width: 12rem;
    height: 12rem;
    grid-row: 2;
  }
  
  .left-r>:nth-child(4) {
    width: 12rem;
    height: 12rem;
    grid-row: 2;
  }
  
  .left-r>:nth-child(5) {
    width: 12rem;
    height: 12rem;
    grid-row: 2;
    grid-column: 3/4;
  }
  
  .right-r {
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
  
  .right-r>span{
    font-size: 35px;
    font-weight: bold;
    color: var(--green);
  }
  
  .right-r>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
  }
  .details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
  }
  .details-r>div>img{
    width: 1rem;
    height: 1rem;
  }
  
  @media screen and (max-width: 768px){
    .Reasons{
      flex-direction: column;
      margin: 135% 0px -30px 0px;
    }
    .left-r{
      display: flex;
      overflow: hidden;
      flex-direction: column;
      align-items: center;
    }
    .left-r>img {
      margin-top: 7rem;
      object-fit: cover;
    }
    .left-r>:nth-child(1){
      width: 20rem;
      height: 23rem;
    }
    .left-r>:nth-child(2){
      width: 20rem;
      height: 7rem;
    }
    .left-r>:nth-child(3){
      width: 12rem;
      height: 12rem;
    }
    .left-r>:nth-child(4){
      width: 12rem;
      height: 12rem;
    }
    .left-r>:nth-child(5){
      width: 0rem;
      height: 0rem;
    }
    .right-r{
      flex-direction: column;
    }
  }