.cards{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

}
@media screen and ( max-width: 768px){
    .container{
        margin-top: 60px;
    }
    .cards{
        min-width: 310px;
        flex-direction: column;
    }
}